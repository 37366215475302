const io = require('socket.io-client');
const feathers = require('@feathersjs/feathers');
const socketio = require('@feathersjs/socketio-client');
const auth = require('@feathersjs/authentication-client');

export const API_URL = process.env.REACT_APP_TICKER_API;
export const STORAGE_URL = process.env.REACT_APP_STORAGE_API;
export const AVATAR_URL = process.env.REACT_APP_AVATARURL;
export const IMAGE_URL = process.env.REACT_APP_IMAGEURL;
export const TRADINGBRIDGE = process.env.REACT_APP_TRADING_API;
export const BROKERID = process.env.REACT_APP_BROKER_ID;

export const EMAIL_METHOD = process.env.REACT_APP_EMAIL_METHOD;
export const EMAIL_API = process.env.REACT_APP_EMAIL_API;

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const INVESTOR_API_URL = process.env.REACT_APP_INVESTOR_API;

export const BROKER_LOGO = process.env.REACT_APP_LOGO;
export const BROKER_LOGO_WIDTH = process.env.REACT_APP_LOGO_WIDTH;
export const BROKER_LOGO_SYMBOL = process.env.REACT_APP_LOGO_SYMBOL;
export const BROKER_BG = process.env.REACT_APP_BG;
export const BROKER_NAME = process.env.REACT_APP_BROKERNAME;
export const BROKER_SHORT = process.env.REACT_APP_BROKERSHORT;
export const BROKER_POSITION = process.env.REACT_APP_BROKERPOSITION;
export const BROKER_EMAIL = process.env.REACT_APP_BROKER_EMAIL;
export const BROKER_EMAIL_LOGO = process.env.REACT_APP_EMAIL_LOGO;
export const BROKER_EMAIL_MASTHEAD = process.env.REACT_APP_EMAIL_MASTHEAD;

export const COLOR_SCHEME = process.env.REACT_APP_COLOR_MODE;

export const ENABLED_CURRENCIES = JSON.parse(process.env.REACT_APP_CURRENCIES);
export const CREDIT_CARD_DEPOSIT = process.env.REACT_APP_CCPROVIDER;
export const CXPAY_URL = 'https://de.earlybirdfunding.com/CXAPM.php';

export const ENABLED_LANGUAGES = JSON.parse(process.env.REACT_APP_LANGUAGES);
export const SMS_VERIFICATION = (process.env.REACT_APP_ENABLE_SMS_VERIFICATION === 'true');

export const USERNAME_FIELD = (process.env.REACT_APP_USERNAME_FIELD) ? process.env.REACT_APP_USERNAME_FIELD : null;

let agents = false;

try {
  agents = JSON.parse(process.env.REACT_APP_AGENTS);
} catch (e){
  agents = false;
}

export const AGENTS = agents;

const socket = io(process.env.REACT_APP_INVESTOR_API,{
  transports: ['websocket'],
  forceNew: true
});

const client = feathers();
client.configure(auth({
  strategy: 'local',
  storage: window.localStorage
}));
client.configure(socketio(socket, {timeout:40000}));

export const API = client;


export function CONSOLE(output){
  if(process.env.NODE_ENV !== 'production'){
    console.log(output)
  }
}